import React from "react"
import styled from "styled-components"
import { colors } from "../../../../../theme"
import { Button, Pencil } from "nzk-react-components"

interface IProps {
  title: string
  children: any
  editable?: boolean
  onEdit?: () => void
}

const Wrapper = styled.div`
  position: relative;
  background-color: #fff;
  margin: 5px;
  padding: 12px;
  border-radius: 12px;
  > :first-child {
    font-weight: bold;
    color: ${colors.indigo};
    font-size: 16px;
    margin-bottom: 12px;
  }
  > :nth-child(2) * {
    margin-bottom: 8px;
  }
`

const EditButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`

export const SectionField = styled.div`
  display: flex;
  > :first-child {
    margin-right: 8px;
    font-weight: bold;
  }
`

export default (props: IProps) => {
  return (
    <Wrapper>
      <div>{props.title}</div>
      <div>{props.children}</div>
      {props.editable && (
        <EditButton>
          <Button theme="yellow" size="small" round onClick={props.onEdit}>
            <Pencil />
          </Button>
        </EditButton>
      )}
    </Wrapper>
  )
}
