import { Button } from "nzk-react-components"
import React, { useMemo } from "react"
import styled from "styled-components"
import useModal from "../../../../hooks/useModal"
import Address from "./components/Address"
import Customer from "./components/Customer"
import Order from "./components/Order"
import Payment from "./components/Payment"
import Section from "./components/Section"
import SubOrder from "./components/SubOrder"
import UpdateBillingModal from "./components/UpdateBillingModal"
import UpdateCustomerDetailsModal from "./components/UpdateCustomerDetailsModal"
import UpdateShippingModal from "./components/UpdateShippingModal"
import { OrderStateProvider, useOrderState } from "./index.state"

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const SubOrders = styled.div`
  margin-top: 15px;
  > * {
    margin-bottom: 12px;
    padding: 10px 20px;
  }
  > :last-child {
    margin-bottom: 0;
  }
  > :nth-child(2n) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  > :nth-child(2n + 1) {
    background-color: rgba(0, 0, 0, 0.02);
  }
`

const OrderPage = props => {
  const {
    order,
    loadingOrder,
    updateOrder,
    recreatingWarehouseOrder,
    recreateWarehouseOrder,
  } = useOrderState()
  const modal = useModal()

  if (loadingOrder) return <div>Loading...</div>
  if (!order) return <div>Oops?...</div>

  const onCustomerEditAction = () => {
    const m = modal.open(
      <UpdateCustomerDetailsModal
        order={order}
        updateOrder={updateOrder}
        dismiss={() => modal.close(m)}
      />
    )
  }

  const onShippingEditAction = () => {
    const m = modal.open(
      <UpdateShippingModal
        order={order}
        updateOrder={updateOrder}
        dismiss={() => modal.close(m)}
      />
    )
  }

  const onBillingEditAction = () => {
    const m = modal.open(
      <UpdateBillingModal
        order={order}
        updateOrder={updateOrder}
        dismiss={() => modal.close(m)}
      />
    )
  }

  const needsReShipping = useMemo(() => {
    return order.subOrders.filter(s => !s.warehouseOrderId).length > 0
  }, [order])

  return (
    <Wrapper>
      <Section title="Order">
        <Order {...order} />
      </Section>
      <Section title="Payment">
        <Payment {...order} />
      </Section>
      <Section title="Customer" editable onEdit={onCustomerEditAction}>
        <Customer {...order} />
      </Section>
      <Section title="Shipping Address" editable onEdit={onShippingEditAction}>
        <Address {...order.shippingAddress} name={order.shippingName} />
      </Section>
      <Section title="Billing Address" editable onEdit={onBillingEditAction}>
        <Address {...order.billingAddress} name={order.customerName} />
      </Section>
      <Section title="Sub orders">
        {needsReShipping && (
          <Button
            theme="primary"
            size="small"
            onClick={() => {
              if (recreatingWarehouseOrder) return
              recreateWarehouseOrder()
            }}
          >
            {recreatingWarehouseOrder ? "Loading..." : "Create warehouse order"}
          </Button>
        )}
        <SubOrders>
          {order.subOrders.map(subOrder => (
            <SubOrder key={subOrder.warehouse} {...subOrder} />
          ))}
        </SubOrders>
      </Section>
    </Wrapper>
  )
}

export default props => (
  <OrderStateProvider initialState={props}>
    <OrderPage />
  </OrderStateProvider>
)
