import useConfirmModal from "../../../../../hooks/useConfirmModal"
import { Button } from "nzk-react-components"
import React from "react"
import styled from "styled-components"
import { colors } from "../../../../../theme"
import { useOrderState } from "../index.state"
import { SectionField } from "./Section"
import { FormattedDate } from "react-intl"

interface LineItem {
  sku: string
  price_data: {
    currency: string
    unit_amount: number
    product_data: {
      name: string
      description: string
      images: string[]
    }
  }
  quantity: number
}

interface IProps {
  _id: string
  paid: boolean
  status: string
  subOrders: {
    lineItems: LineItem[]
  }[]
  createdAt: number
  hsContactId: string
  hsDealId: string
}

const Wrapper = styled.div`
  .pill {
    margin: 0 4px;
    padding: 2px 6px;
  }
  .error {
    background-color: ${colors.red};
    color: #fff;
  }
  .success {
    background-color: ${colors.green};
    color: #fff;
  }
  a {
    text-decoration: underline;
    color: ${colors.indigo};
  }
`

const Actions = styled.div`
  margin-top: 12px;
  display: flex;
  > * {
    margin-right: 12px;
  }
  > :last-child {
    margin-right: 0;
  }
`

export default (props: IProps) => {
  const { cancelOrder, cancellingOrder } = useOrderState()
  const amount = props.subOrders.reduce((acc, subOrder) => {
    return (
      acc +
      subOrder.lineItems.reduce((acc2, lineItem) => {
        return acc2 + lineItem.price_data.unit_amount * lineItem.quantity
      }, 0)
    )
  }, 0)

  const currency = props.subOrders[0].lineItems[0].price_data.currency

  const confirmCancelOrder = useConfirmModal({
    title: "Are you sure?",
    message:
      "Cancelling the order will cancel the order from being shipped if it hasn't already has (it will have no effect if it has already been shipped), you will need to refund",
    onConfirm: cancelOrder,
    onCancel: () => {},
  })

  return (
    <Wrapper>
      <SectionField>
        <div>Order ID:</div>
        <div>{props._id}</div>
      </SectionField>
      <SectionField>
        <div>Date:</div>
        <div>
          <FormattedDate
            value={props.createdAt}
            day="2-digit"
            month="2-digit"
            year="numeric"
            hour="2-digit"
            minute="2-digit"
            second="2-digit"
          />
        </div>
      </SectionField>
      <SectionField>
        <div>Paid:</div>
        {props.paid ? (
          <span className="pill success">Yes</span>
        ) : (
          <span className="pill error">No</span>
        )}
      </SectionField>
      <SectionField>
        <div>Status:</div>
        <div>{props.status}</div>
      </SectionField>
      <SectionField>
        <div>Price (excl. shipping):</div>
        {new Intl.NumberFormat("en-us", {
          style: "currency",
          currency: currency,
        }).format(amount / 100)}
      </SectionField>
      <SectionField>
        <div>Hubspot contact:</div>
        <a
          href={`https://app.hubspot.com/contacts/19679358/contact/${props.hsContactId}/`}
          target="_blank"
        >
          {props.hsContactId}
        </a>
      </SectionField>
      <SectionField>
        <div>Hubspot Deal:</div>
        <a
          href={`https://app.hubspot.com/contacts/19679358/deal/${props.hsDealId}/`}
          target="_blank"
        >
          {props.hsDealId}
        </a>
      </SectionField>
      <Actions>
        <Button theme="red" onClick={confirmCancelOrder} size="x-small">
          Cancel Order
        </Button>
      </Actions>
    </Wrapper>
  )
}
