import React, { useMemo, useState } from "react"
import styled from "styled-components"
import { useOrderState } from "../index.state"
import { Order } from "../order"
import UpdateModal, { Fields, Field, Input } from "./UpdateModal"

interface IProps {
  order: Order.IOrder
  updateOrder: (payload: any) => void
  dismiss: () => void
}

const FIELDS = [
  { key: "city", title: "City" },
  { key: "line1", title: "Line 1" },
  { key: "line2", title: "Line 2" },
  { key: "country", title: "Country" },
  { key: "postal_code", title: "Postal Code" },
  { key: "state", title: "State" },
]

export default (props: IProps) => {
  const { order, dismiss, updateOrder } = props
  const [newData, setNewData] = useState(
    FIELDS.reduce((acc, field) => {
      acc[field.key] = order.shippingAddress[field.key]
      return acc
    }, {})
  )

  const payload = useMemo(() => {
    return Object.keys(newData).reduce((acc, key) => {
      if (newData[key] !== order.shippingAddress[key]) {
        acc[key] = newData[key]
      }
      return acc
    }, {})
  }, [order, newData])

  const canSave = useMemo(() => {
    return Object.keys(payload).length > 0
  }, [payload])

  const onSave = () => {
    if (canSave) {
      updateOrder({ billingAddress: payload })
      dismiss()
    }
  }

  const onCancel = () => {
    dismiss && dismiss()
  }

  return (
    <UpdateModal onSave={onSave} onCancel={onCancel} canSave={canSave}>
      <Fields>
        {FIELDS.map(field => (
          <Field key={field.key}>
            <div>{field.title}</div>
            <Input
              placeholder={field.title}
              value={newData[field.key]}
              onChange={e =>
                setNewData(p => {
                  let newD = { ...p }
                  newD[field.key] = e.target.value
                  return newD
                })
              }
            />
          </Field>
        ))}
      </Fields>
    </UpdateModal>
  )
}
