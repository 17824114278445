import React from "react"
import styled from "styled-components"
import { colors } from "../../../../../theme"
import { SectionField } from "./Section"

interface IProps {
  status: string
  paymentType: "PAYPAL" | "STRIPE"
  paypalTransactionId?: string
  stripePaymentId?: string
  receiptUrl?: string
}

const Wrapper = styled.div`
  a {
    text-decoration: underline;
    color: ${colors.indigo};
  }
`

export default (props: IProps) => {
  return (
    <Wrapper>
      {props.paymentType === "PAYPAL" && (
        <>
          <SectionField>
            <div>Paid through:</div>
            <div>PAYPAL</div>
          </SectionField>
          <div>Paypal Transaction: {props.paypalTransactionId}</div>
          <p>To refund, please log in to the Paypal dashboard</p>
        </>
      )}
      {(!props.paymentType || props.paymentType === "STRIPE") && (
        <>
          <SectionField>
            <div>Paid through:</div>
            <div>STRIPE</div>
          </SectionField>
          <SectionField>
            <div>Stripe payment ID:</div>
            <a
              href={`https://dashboard.stripe.com/payments/${props.stripePaymentId}`}
              target="_blank"
            >
              {props.stripePaymentId}
            </a>
          </SectionField>
          <div>
            <a href={props.receiptUrl} target="_blank">
              Stripe Receipt
            </a>
          </div>
          <p>To refund, please go on Stripe using the link above</p>
        </>
      )}
    </Wrapper>
  )
}
