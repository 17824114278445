import { useApolloClient } from "@apollo/client"
import useAsync from "../../../../hooks/useAsync"
import GET_ORDER from "./graphql/getOrder.graphql"
import RECREATE_WAREHOUSE_ORDER from "./graphql/recreateWarehouseOrder.graphql"
import CANCEL_ORDER from "./graphql/shop_cancelOrder.graphql"
import PRINT_ORDER from "./graphql/shop_printOrder.graphql"
import REGENERATE_BOOK from "./graphql/shop_regenerateBook.graphql"
import REGENERATE_FILES_URLS from './graphql/shop_regenerateFilesUrls.graphql'
import REPLACE_CHARLESWORTH_FILES from "./graphql/shop_replaceCharlesworthFiles.graphql"
import UPDATE_ORDER from "./graphql/updateOrder.graphql"

const useData = () => {
  const client = useApolloClient()

  const { execute: fetchOrder, data: order, loading: loadingOrder } = useAsync({
    asyncFunc: async params => {
      const { data } = await client.query({
        query: GET_ORDER,
        variables: {
          ...params,
        },
        errorPolicy: "all",
        fetchPolicy: "network-only",
      })
      return data.shop_order
    },
    immediate: false,
    funcParams: {},
    initialData: null,
  })

  const { execute: updateOrder, loading: updatingOrder } = useAsync({
    asyncFunc: async params => {
      const { data } = await client.mutate({
        mutation: UPDATE_ORDER,
        variables: {
          ...params,
        },
      })
      if (data.shop_updateOrder._id) {
        fetchOrder({ orderId: data.shop_updateOrder._id })
      }
      return data.shop_updateOrder
    },
    immediate: false,
    funcParams: {},
    initialData: null,
  })

  const {
    execute: recreateWarehouseOrder,
    loading: recreatingWarehouseOrder,
  } = useAsync({
    asyncFunc: async params => {
      const { data } = await client.mutate({
        mutation: RECREATE_WAREHOUSE_ORDER,
        variables: {
          ...params,
        },
      })
      if (data.shop_recreateWarehouseOrder._id) {
        fetchOrder({ orderId: data.shop_recreateWarehouseOrder._id })
      }
      return data.shop_recreateWarehouseOrder
    },
    immediate: false,
    funcParams: {},
    initialData: null,
  })

  const { execute: cancelOrder, loading: cancellingOrder } = useAsync({
    asyncFunc: async params => {
      const { data } = await client.mutate({
        mutation: CANCEL_ORDER,
        variables: {
          ...params,
        },
      })
      if (data.shop_cancelOrder._id) {
        fetchOrder({ orderId: data.shop_cancelOrder._id })
      }
      return data.shop_cancelOrder
    },
    immediate: false,
    funcParams: {},
    initialData: null,
  })

  const { execute: printOrder, loading: printingOrder } = useAsync({
    asyncFunc: async params => {
      const { data } = await client.mutate({
        mutation: PRINT_ORDER,
        variables: {
          ...params,
        },
      })
      if (data.shop_printOrder._id) {
        fetchOrder({ orderId: data.shop_printOrder._id })
      }
      return data.shop_printOrder
    },
    immediate: false,
    funcParams: {},
    initialData: null,
  })

  const { execute: regenerateBook, loading: regeneratingBook } = useAsync({
    asyncFunc: async params => {
      const { data } = await client.mutate({
        mutation: REGENERATE_BOOK,
        variables: {
          ...params,
        },
      })
      if (data.shop_regenerateBook._id) {
        fetchOrder({ orderId: data.shop_regenerateBook._id })
      }
      return data.shop_regenerateBook
    },
    immediate: false,
    funcParams: {},
    initialData: null,
  })

  const {
    execute: replaceCharlesworthFiles,
    loading: replacingCharlesworthFiles,
  } = useAsync({
    asyncFunc: async params => {
      const { data } = await client.mutate({
        mutation: REPLACE_CHARLESWORTH_FILES,
        variables: {
          ...params,
        },
      })
      if (data.shop_replaceCharlesworthFiles._id) {
        fetchOrder({ orderId: data.shop_replaceCharlesworthFiles._id })
      }
      return data.shop_replaceCharlesworthFiles
    },
    immediate: false,
    funcParams: {},
    initialData: null,
  })

  const {
    execute: regenerateFilesUrls,
    loading: regeneratingFilesUrls,
  } = useAsync({
    asyncFunc: async params => {
      const { data } = await client.mutate({
        mutation: REGENERATE_FILES_URLS,
        variables: {
          ...params,
        },
      })
      if (data.shop_regenerateFilesUrls._id) {
        fetchOrder({ orderId: data.shop_regenerateFilesUrls._id })
      }
      return data.shop_regenerateFilesUrls
    },
    immediate: false,
    funcParams: {},
    initialData: null,
  })

  return {
    fetchOrder,
    order,
    loadingOrder,
    recreateWarehouseOrder,
    recreatingWarehouseOrder,
    updateOrder,
    updatingOrder,
    cancelOrder,
    cancellingOrder,
    printOrder,
    printingOrder,
    regenerateBook,
    regeneratingBook,
    replaceCharlesworthFiles,
    replacingCharlesworthFiles,
    regenerateFilesUrls,
    regeneratingFilesUrls,
  }
}

export default useData
