import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { colors } from "../../../../../theme"
import { SectionField } from "./Section"

interface IProps {
  customerName: string
  customerPhone: string
  customerEmail: string
  user?: {
    _id: string
    email: string
  }
}

const Wrapper = styled.div`
  a {
    text-decoration: underline;
    color: ${colors.indigo};
  }
`

export default (props: IProps) => {
  return (
    <Wrapper>
      <SectionField>
        <div>Name:</div>
        <div>
          {props.user && (
            <Link to={`/customer/${props.user.email}`}>
              {props.customerName}
            </Link>
          )}
          {!props.user && (props.customerName || "Not specified")}
        </div>
      </SectionField>
      <SectionField>
        <div>Email:</div>
        <div>{props.customerEmail || "Not specified"}</div>
      </SectionField>
      <SectionField>
        <div>Phone:</div>
        <div>{props.customerPhone || "Not specified"}</div>
      </SectionField>
    </Wrapper>
  )
}
