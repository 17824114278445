import { Button, Close } from "nzk-react-components"
import React from "react"
import styled from "styled-components"
import { colors } from "../../../../../theme"

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
`

const Wrapper = styled.div`
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 5px 0 #afafaf, 0 9px 0 rgba(0, 0, 0, 0.4);
`

export const Fields = styled.div`
  > * {
    margin-bottom: 12px;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

export const Field = styled.div`
  > :first-child {
    font-size: 16px;
    font-weight: bold;
    color: ${colors.indigo};
    margin-bottom: 5px;
  }
  width: 100%;
`

export const Input = styled.input`
  padding: 4px 8px;
  border-radius: 6px;
  border: 2px solid ${colors.indigo};
`

const Actions = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  justify-content: center;
  > * {
    margin-right: 12px;
  }
  > :last-child {
    margin-right: 0;
  }
`

const CloseButton = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
`

interface IProps {
  children: any
  onSave?: () => any
  onCancel?: () => any
  canSave?: boolean
}

export default (props: IProps) => {
  return (
    <Overlay>
      <Wrapper>
        {props.onCancel && (
          <CloseButton>
            <Button theme="red" size="small" round onClick={props.onCancel}>
              <Close />
            </Button>
          </CloseButton>
        )}
        {props.children}
        <Actions>
          {props.onSave && (
            <Button
              theme="confirm"
              size="small"
              disabled={!props.canSave}
              onClick={() => props.canSave && props.onSave!()}
            >
              Save
            </Button>
          )}
          {props.onCancel && (
            <Button theme="primary" size="small" onClick={props.onCancel}>
              Cancel
            </Button>
          )}
        </Actions>
      </Wrapper>
    </Overlay>
  )
}
