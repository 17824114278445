import React, { useMemo, useState } from "react"
import styled from "styled-components"
import { useOrderState } from "../index.state"
import { Order } from "../order"
import UpdateModal, { Fields, Field, Input } from "./UpdateModal"

interface IProps {
  order: Order.IOrder
  updateOrder: (payload: any) => void
  dismiss: () => void
}

export default (props: IProps) => {
  const { order, dismiss, updateOrder } = props
  const [newData, setNewData] = useState({
    customerName: order.customerName,
    customerPhone: order.customerPhone,
    customerEmail: order.customerEmail,
  })

  const payload = useMemo(() => {
    return Object.keys(newData).reduce((acc, key) => {
      if (newData[key] !== order[key]) {
        acc[key] = newData[key]
      }
      return acc
    }, {})
  }, [order, newData])

  const canSave = useMemo(() => {
    return Object.keys(payload).length > 0
  }, [payload])

  const onSave = () => {
    if (canSave) {
      updateOrder(payload)
      dismiss()
    }
  }

  const onCancel = () => {
    dismiss && dismiss()
  }

  return (
    <UpdateModal onSave={onSave} onCancel={onCancel} canSave={canSave}>
      <Fields>
        <Field>
          <div>Customer Name</div>
          <Input
            placeholder="Customer Name"
            value={newData.customerName}
            onChange={e =>
              setNewData(p => ({ ...p, customerName: e.target.value }))
            }
          />
        </Field>
        <Field>
          <div>Customer Email</div>
          <Input
            placeholder="Customer Email"
            value={newData.customerEmail}
            onChange={e =>
              setNewData(p => ({ ...p, customerEmail: e.target.value }))
            }
          />
        </Field>
        <Field>
          <div>Customer Phone</div>
          <Input
            placeholder="Customer Phone"
            value={newData.customerPhone}
            onChange={e =>
              setNewData(p => ({ ...p, customerPhone: e.target.value }))
            }
          />
        </Field>
      </Fields>
    </UpdateModal>
  )
}
