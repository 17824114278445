import { useEffect } from "react"
import { createContainer } from "unstated-next"
import useConfirmModal from '../../../../hooks/useConfirmModal'
import useData from "./useData"

const OrderState = props => {
  const {
    loadingOrder,
    fetchOrder,
    order,
    updateOrder,
    updatingOrder,
    recreateWarehouseOrder,
    recreatingWarehouseOrder,
    cancelOrder,
    cancellingOrder,
    printOrder,
    printingOrder,
    regenerateBook,
    regeneratingBook,
    replaceCharlesworthFiles,
    replacingCharlesworthFiles,
    regenerateFilesUrls,
    regeneratingFilesUrls,
  } = useData()

  const confirmRegenerateFilesUrls = useConfirmModal({
    title: "Resend email?",
    message:
      "The same email they received originally will be triggered with the updated file(s) urls.",
    onConfirm: () => regenerateFilesUrls({ orderId: order._id, resendEmail: true }),
    onCancel: () => regenerateFilesUrls({ orderId: order._id, resendEmail: false }),
  })

  useEffect(() => {
    fetchOrder({ orderId: props.orderId })
  }, [])

  return {
    loadingOrder,
    updateOrder: input => updateOrder({ orderId: order._id, input }),
    updatingOrder,
    recreateWarehouseOrder: () =>
      recreateWarehouseOrder({ orderId: order._id }),
    recreatingWarehouseOrder,
    cancelOrder: () => cancelOrder({ orderId: order._id }),
    cancellingOrder,
    printOrder: () => printOrder({ orderId: order._id }),
    printingOrder,
    regenerateBook: (itemId: string) =>
      regenerateBook({ orderId: order._id, itemId }),
    regeneratingBook,
    replaceCharlesworthFiles: () =>
      replaceCharlesworthFiles({ orderId: order._id }),
    replacingCharlesworthFiles,
    regenerateFilesUrls: () => confirmRegenerateFilesUrls(),
    regeneratingFilesUrls,
    order,
  }
}

const OrderStateContainer = createContainer(OrderState)

export const OrderStateProvider = OrderStateContainer.Provider
export const useOrderState = OrderStateContainer.useContainer
