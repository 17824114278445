import { Button } from "nzk-react-components"
import React from "react"
import styled from "styled-components"
import { colors } from "../../../../../theme"
import { useOrderState } from "../index.state"
import { SectionField } from "./Section"

interface LineItem {
  sku: string
  price_data: {
    currency: string
    unit_amount: number
    product_data: {
      name: string
      description: string
      images: string[]
    }
  }
  itemId?: string
  quantity: number
  file?: string
  printableFiles?: {
    type: "BOOK-INNER" | "BOOK-COVER"
    url: string
    md5: string
  }[]
  pageCount?: number
}

interface IProps {
  warehouse: "ONEFLOW" | "MINTSOFT" | "NZK" | "CHARLESWORTH"
  warehouseOrderId: string
  lineItems: LineItem[]
  status: string
}

const Wrapper = styled.div`
  position: relative;
  .error {
    background-color: ${colors.red};
    color: #fff;
  }
  .success {
    background-color: ${colors.green};
    color: #fff;
  }
  .important-message {
    position: absolute;
    top: 10px;
    right: 10px;
    display: inline-flex;
    padding: 4px 8px;
    font-weight: bold;
  }
`

const Items = styled.div`
  margin-left: 15px;
`

const Item = styled.div`
  img {
    height: 50px;
    width: 50px;
    object-fit: contain;
    object-position: center;
    margin: 0;
  }
  > .row {
    display: flex;
    align-items: center;
    width: 100%;
  }
  > .row > * {
    margin-right: 12px;
  }
`

const Title = styled.div`
  margin-bottom: 10px;
  margin-left: -15px;
  font-weight: bold;
`

const Files = styled.div`
  > * {
    margin-right: 20px;
  }
  > :last-child {
    margin-right: 0;
  }
  a {
    text-decoration: underline;
  }
`

const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  > * {
    margin-right: 20px;
  }
  > :last-child {
    margin-right: 0;
  }
`

export default (props: IProps) => {
  const {
    regenerateBook,
    regeneratingBook,
    printOrder,
    printingOrder,
    replaceCharlesworthFiles,
    regenerateFilesUrls,
    regeneratingFilesUrls,
    replacingCharlesworthFiles,
  } = useOrderState()

  return (
    <Wrapper>
      <SectionField>
        <div>Warehouse:</div>
        <div>{props.warehouse}</div>
      </SectionField>
      { props.warehouse === 'NZK' && <SectionField>
        <Button theme='primary' size='x-small' onClick={() => !regeneratingFilesUrls && regenerateFilesUrls()}>{ !regeneratingFilesUrls ? 'Regenerate files and email' : 'Loading...' }</Button>
      </SectionField> }
      {!props.warehouseOrderId && (
        <>
          <div className="error important-message">FAILED</div>
          <p>
            We failed to create a warehouse order for this order, this could be
            due to the data we sent to the warehouse (shipping address, ...) or
            server issue. If you think the shipping address is missing bits,
            please update it and re-create the warehouse order. If you think
            everything is looking ok, re-create the warehouse order. If it's
            still failing, please let Ben know.
          </p>
        </>
      )}
      {props.warehouseOrderId && (
        <div className="success important-message">
          {props.warehouse === "CHARLESWORTH" ? props.status : "CREATED"}
        </div>
      )}
      <Items>
        <Title>Items</Title>
        {props.lineItems.map(item => (
          <Item>
            <div className="row">
              <div>
                <img
                  src={item.price_data.product_data.images[0]}
                  alt="Product"
                />
              </div>
              <div>
                {item.price_data.product_data.name} x{item.quantity}
              </div>
              <div>
                {new Intl.NumberFormat("en-us", {
                  style: "currency",
                  currency: item.price_data.currency,
                }).format((item.price_data.unit_amount * item.quantity) / 100)}
              </div>
            </div>
            {item.itemId && props.warehouse === "CHARLESWORTH" && (
              <>
                <p>Please allow 20 minutes after you generate book</p>
                <Actions>
                  <Button
                    theme="primary"
                    size="x-small"
                    disabled={regeneratingBook}
                    onClick={() => {
                      if (regeneratingBook) return
                      regenerateBook(item.itemId!)
                    }}
                  >
                    Generate book
                  </Button>
                  {props.status !== "CREATED" &&
                    (item.printableFiles || []).length > 0 && (
                      <Button
                        theme="confirm"
                        size="x-small"
                        disabled={printingOrder}
                        onClick={() => {
                          if (printingOrder) return
                          printOrder()
                        }}
                      >
                        Send to print
                      </Button>
                    )}
                  {props.status === "CREATED" &&
                    (item.printableFiles || []).length > 0 && (
                      <Button
                        theme="orange"
                        size="x-small"
                        disabled={replacingCharlesworthFiles}
                        onClick={() => {
                          if (replacingCharlesworthFiles) return
                          replaceCharlesworthFiles()
                        }}
                      >
                        Replace print files
                      </Button>
                    )}
                </Actions>
              </>
            )}
            {item.file &&  <a
                href={item.file}
                target="_blank"
                rel="noreferrer noopener"
              >
                Download file
              </a>
             }
            {item.printableFiles && (
              <Files>
                {item.printableFiles.map(file => {
                  return (
                    <a
                      href={file.url}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {file.type === "BOOK-COVER"
                        ? "Download cover"
                        : "Download content"}
                    </a>
                  )
                })}
              </Files>
            )}
          </Item>
        ))}
      </Items>
    </Wrapper>
  )
}
