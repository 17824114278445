import { PageProps } from 'gatsby'
import React from 'react'
import OrderPage from '../../components/pages/Shop/OrderPage'

const OrderPageExported = (props: PageProps) => {
  if (typeof window === "undefined") return null

  return <OrderPage orderId={props.params.orderId} />
}

export default OrderPageExported
