import React from "react"
import styled from "styled-components"
import { Order } from "../order"
import { SectionField } from "./Section"

interface IProps extends Order.IAddress {
  name: string
}

const Wrapper = styled.div``

export default (props: IProps) => {
  return (
    <Wrapper>
      <SectionField>
        <div>Name:</div>
        <div>{props.name || "Not specified"}</div>
      </SectionField>
      <SectionField>
        <div>City:</div>
        <div>{props.city || "Not specified"}</div>
      </SectionField>
      <SectionField>
        <div>Country:</div>
        <div>{props.country || "Not specified"}</div>
      </SectionField>
      <SectionField>
        <div>Line 1:</div>
        <div>{props.line1 || "Not specified"}</div>
      </SectionField>
      <SectionField>
        <div>Line 2:</div>
        <div>{props.line2 || "Not specified"}</div>
      </SectionField>
      <SectionField>
        <div>Post code:</div>
        <div>{props.postal_code || "Not specified"}</div>
      </SectionField>
      <SectionField>
        <div>State:</div>
        <div>{props.state || "Not specified"}</div>
      </SectionField>
    </Wrapper>
  )
}
